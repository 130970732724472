<template>
  <div class="SetLine">
    <div class="flex">
      <div class="df-item">{{!flage?'编辑小号' : '批量增加小号'}}</div>
    </div>
    <el-divider style="margin:12px 0 24px;"></el-divider>
              <el-form label-position="left" label-width="100px" :model="upform" ref="upform1" :rules="rules">

      <div class="box">
        <div class="left">
          <div>
            <div class="popup-view">

                <div class="center-body">
                  <!-- <el-row :gutter="12">
              <el-col :span="8"> -->
                  <div class="form-out-box p-0-30">
                    <el-form-item label="号码：" prop="number">
                      <el-input v-if="flage" v-model="upform.number" :autosize="{ minRows: 3, maxRows: 4 }" type="textarea" placeholder="请输入号码(输入为多个号码时请换行输入)"></el-input>
                      <el-input v-else v-model.trim="upform.number" placeholder="请输入号码"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="错误号码：" class="redItem" v-if="showError == true">
                      <div class="redItem">{{ errorNumber }}</div>
                    </el-form-item> -->
                    <!-- </el-col>
                  <el-col :span="8"> -->
                    <el-form-item label="号码设置：">
                      <el-input v-model="upform.options" type="textarea" placeholder="请填写号码设置" :autosize="{ minRows: 2, maxRows: 6 }" />
                    </el-form-item>
                    <!-- <el-form-item label="地区" prop="areaCode">
                <el-cascader :options="options" :props="{value: 'name',label: 'name'}" v-model="upformArea" @change="upformAreaChange"></el-cascader>
              </el-form-item> -->
                    <el-form-item label="省份："   prop="value3"      >
        <el-cascader  filterable clearable  placeholder="选择省份城市" style="width: 100%"    v-model="upform.value3" :options="option"></el-cascader>
                    </el-form-item>
                    <!-- </el-col>
                  <el-col :span="8"> -->
                    <!-- <el-form-item label="城市：">
                      <el-input v-model="upform.city" placeholder="请输入城市"></el-input>
                    </el-form-item> -->
                    <!-- </el-col>
                  <el-col :span="8"> -->
                    <el-form-item label="区号：" prop="areaCode">
                      <el-input v-model="upform.areaCode" placeholder="请输入区号"></el-input>
                    </el-form-item>
                    <!-- </el-col> -->
                    <!-- <el-col :span="8">-->
                    <el-form-item label="服务商：" prop="amountId">
                      <el-select v-model="upform.amountId" placeholder="请选择" filterable style="width: 100%" clearable @change="changeValues">
                        <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <!-- </el-col>  -->
                    <!-- <el-col :span="8"> -->
                    <el-form-item label="接口类型：" prop="interfaceId">
                      <el-select v-model="upform.interfaceId" placeholder="请选择" filterable style="width: 100%" clearable>
                        <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <!-- </el-col>
                  <el-col :span="8"> -->
                    <el-form-item label="号码状态：" prop="status">
                      <el-select v-model="upform.status" placeholder="请选择" style="width: 100%" clearable>
                        <el-option v-for="(item, index) in ['正常', '禁用']" :key="index" :label="item" :value="index">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <!-- </el-col>
                  <el-col :span="8"> -->
                    <el-form-item label="计费类型：" prop="type">
                      <el-select v-model="upform.type" placeholder="请选择" style="width: 100%" clearable>
                        <el-option v-for="(item, index) in ['单计费', '双计费']" :key="index" :label="item" :value="index + 1">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <!-- </el-col>
                  <el-col :span="8"> -->
                 
                    <!-- </el-col>
                  <el-col :span="8"> -->
                    <el-form-item label="是否录音：" prop="record">
                      <el-select v-model="upform.record" placeholder="请选择" style="width: 100%" clearable>
                        <el-option v-for="(item, index) in ['否', '是']" :key="index" :label="item" :value="index">
                        </el-option>
                      </el-select>
                    </el-form-item>
                      <el-form-item>
                      <div class="buttonli">
            <el-button class="button-left" @click="resetForm('form')">取消</el-button>
            <el-button class="button-right" @click="addSubmit('upform1')">{{ type == 'set' ? '确认' : '保存' }}</el-button>
          </div>
                    </el-form-item>
                  </div>
          
                  <!-- </el-col>
            </el-row> -->
                </div>

            
            </div>

          </div>

         

        </div>

        <div class="right">
         <div class="popup-view">
        

                <div class="center-body">
                  <!-- <el-row :gutter="12">
              <el-col :span="8"> -->
                  <div class="form-out-box p-0-30">
                   
                    <el-form-item >
                    </el-form-item>
                    
                    <el-form-item >
                    </el-form-item>
                    <el-form-item >
                    </el-form-item>
                    <el-form-item >
                    </el-form-item>
                    <el-form-item >
                    </el-form-item>
                    
                    <el-form-item >
                    </el-form-item>
                    <el-form-item >
                    </el-form-item>
                    <el-form-item >
                    </el-form-item>
                    <el-form-item >
                    </el-form-item>
                    
                    <el-form-item >
                    </el-form-item>
                    <el-form-item >
                    </el-form-item>
                      <el-form-item >
                    </el-form-item>
                    
                    <!-- </el-col>
                  <el-col :span="8"> -->
                    <el-form-item label="行业：" prop="industryId">
                       <el-select filterable remote placeholder="请选择" v-model="upform.industryId" style="width: 30%" clearable >
            <el-option v-for="item in label " :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
                    </el-form-item>
                    <!-- </el-col> -->
                    <!-- <el-col :span="8">-->
                 
                  </div>
          
                  <!-- </el-col>
            </el-row> -->
                </div>

            
            </div>
<!-- 
   <el-form-item  label="行业："  >
                    
   <el-select filterable remote placeholder="请选择" v-model="upform.industryId" style="width: 30%" clearable size="mini">
            <el-option v-for="item in label " :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

                    </el-form-item> -->

        </div>
      </div>
    </el-form>

    <div>
    
    </div>

  </div>
</template>

<script>
import number from '@/api/open/privacy/number'
import { IconFont } from '@/utils/iconfont'
import amount from '@/api/open/amount/amount'
import { message } from 'ant-design-vue'
import amountts from '@/api/open/privacy/amount'
import dictionary from '@/api/system/dictionary'
import interfacets from '@/api/open/privacy/interface'

export default {
  name: 'SetLine',
  components: {
    IconFont,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      let num = 0,
        types = null,
        errorItem
      if (value == null) {
        this.showError = false
        callback(new Error('请填写号码 (多个号码请换行输入)'))
      } else if (value.length == 0) {
        this.showError = false
        callback(new Error('请填写号码 (多个号码请换行输入)'))
      } else {
        let newArr = []
        newArr = value.split(/[(\r\n)\r\n]+/)
        newArr.forEach((item, i) => {
          if (
            item.match(
              /^(1[3|4|5|6|7|8|9])\d{9}$|^0\d{2,3}-?\d{7,8}$|^9\d{9}$|^2\d{9}$|^8\d{9}$/
            ) == null
            // item.match(
            //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/
            // ) == null
          ) {
            // if(item.match(/(010\d{8})|(0[2-9]\d{9})|(13\d{9})|(14[57]\d{8})|(15[0-35-9]\d{8})|(18[0-35-9]\d{8})/) == null){
            // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
            num++
            types = i
            errorItem = item
          }
        })
        if (num != 0) {
          this.showError = true
          this.errorNumber = errorItem
          callback(
            new Error(
              '请输入正确的号码 (请检查第' +
                (types + 1) +
                '行：' +
                errorItem +
                ')'
            )
          )
        } else {
          this.showError = false
          callback()
        }
      }
    }



    return {
      flage:false,
      value3:[],
       allIds: [],
      upform: {
        //添加号码
        number: '', // 号码
        province: '', // 省份
        city: '', // 城市
        areaCode: '', // 区号
        interfaceId: '', // 接口类型
        amountId: '', // 通信账户ID
        status: '', // 号码状态(0:正常/1:禁用)
        type: '', // 小号类型(1:单计费/2:双计费)
        businessType: '', // (0:非金融/1:房产/2:家装)
        record: '', // 是否录音
        distributionCount: '', // 分配次数
        checkAll: false,
        checkedCities: ['上海', '北京'],
        cities: ['上海', '北京', '广州', '深圳'],
        isIndeterminate: true,
        industryId: '',
      //  value3:[]
      },
      interface_id:[],
      industryList:[],
      label: [],
      type: '',
      optionsNameArr: [],
      centerDialogVisible: false,

      props: { multiple: true },
   
      option: [],
    amount_id :[],
   
      content: '',
        rules: {
        number: [{ required: true, trigger: 'blur', validator: checkNumber }],


        value3: [
          {
            required: true,
            message: '请选择省份城市',
            trigger: 'blur',
          },
        ],
        telA: [
          {
            required: true,
            message: '请填写主叫号码',
            trigger: 'blur',
          },
        ],
        telB: [
          {
            required: true,
            message: '请填写被叫号码',
            trigger: 'blur',
          },
        ],
        expire: [
          {
            required: true,
            message: '请选择绑定时间',
            trigger: 'blur',
          },
        ],
        // number: [
        //   {
        //     required: true,
        //     message: '请填写手机号码',
        //     trigger: 'blur',
        //   },
        //   {
        //     min: 11,
        //     max: 11,
        //     message: '手机号码长度不正确',
        //     trigger: 'blur',
        //   },
        // ],
        areaCode: [
          {
            required: true,
            message: '请输入区号',
            trigger: 'blur',
          },
        ],
        amountId: [
          {
            required: true,
            message: '请选择服务商',
            trigger: 'blur',
          },
        ],
        interfaceId: [
          {
            required: true,
            message: '请选择接口类型',
            trigger: 'blur',
          },
        ],
        status: [
          {
            required: true,
            message: '请选择号码状态',
            trigger: 'blur',
          },
        ],
        type: [
          {
            required: true,
            message: '请选择计费类型',
            trigger: 'blur',
          },
        ],
        industryId: [
          {
            required: true,
            message: '请选择号码行业',
            trigger: 'blur',
          },
        ],
        record: [
          {
            required: true,
            message: '请选择是否录音',
            trigger: 'blur',
          },
        ],
        uid: [
          {
            required: true,
            message: '请选择分配的用户',
            trigger: 'blur',
          },
        ],
      },
      arr: [{ record: '0.4' }],
    }
    
  },

  mounted() {

    if(this.$route.query.id==''){
      this.flage=true
    }
        this.getModeList()

    this.city()
    this.changeValues()
      this.getAmountType()
          console.log(this.$route.query.id, '999999999999999999999=========9999999999')




      if(this.$route.query.id){
    this.upform = JSON.parse(this.$route.query.id)
      }
    this.upform.value3=this.upform.province+','+this.upform.city
   this.upform.value3= this.upform.value3.split(',');

    //   if (this.$route.query.id == 'set') {
    //     this.getModeList()
    //   } else {
    //     this.getModeList()
    //     this.getAmountDetails(this.$route.query.id)
    //     this.getid()
    //   }
    //  this.type= this.$route.query.id
  },
  methods: {

  city() {
      
      dictionary.cityList().then((res) => {
console.log('000')
        let arr = []
        this.getBaseTree(res.data, arr)
        ///  this.modeList = res.data
      })
  },


        // 选中服务商
    changeValues(val) {
     // this.searchForm.interfaceId = ''
      interfacets
        .getInterface({
          amountId: val,
        })
        .then((res) => {
          this.interface_id = res.data
        })
    },
      unique(arr) {
      const res = new Map()
      return arr.filter((a) => !res.has(a) && res.set(a, 1))
    },
  // 获取通信账户类型
    getAmountType() {

      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data
      })

    },
        
       addSubmit(formName) {

console.log(this.value3,'77777777777777777777777777')

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.$route.query.id) {
            let arrLest = []
            let upArr = []
            arrLest = this.upform.number.split(/[(\r\n)\r\n]+/)
            this.unique(arrLest).forEach((item, i) => {
              upArr.push({
                number: item.trim(),
                areaCode: this.upform.areaCode,
                industryId: this.upform.industryId,
                city: this.upform.city,
                province: this.upform.province,
                record: this.upform.record,
                status: this.upform.status,
                type: this.upform.type,
                amountId: this.upform.amountId,
                interfaceId: this.upform.interfaceId,
                city:this.upform.value3[1],
                province:this.upform.value3[0]

              })
            })
            // this.upform.number = this.unique(otherArr)
            number
              .addNumber({
                numberListDtoList: upArr,
              })
              .then((res) => {
                this.centerDialogVisible = false
                if (res.code === 200) {
                  message.success('添加成功')
                   this.$router.push({
        path: '/open/Number',
      })
                  // 获取最新数据
                  this.handleSizeChange(this.searchForm.pageSize)
                  this.upform = {}

       
                } else {
                  message.error(res.message)
                }
              })
          } else {
            if(this.upform.value3){
this.upform.province=this.upform.value3[0]

this.upform.city=this.upform.value3[1]

            }

            number
              .editNumber({
                ...this.upform,
              })
              .then((res) => {
                if (res.code === 200) {
                  this.upformArea = []
                  message.success('修改成功')
                    this.$router.push({
        path: '/open/Number',
      })
                  // 获取最新数据
                  this.handleSizeChange(this.searchForm.pageSize)
                 
                } else {
                  message.error(res.message)
                }
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    
    getWangEditorValue(val) {
      // console.log(val)
      this.form.description = val
    },
    resetForm() {
      this.$router.push({
        path: '/open/Number',
      })
    },

    // 删除标签
    deleteOptions(tag, i) {
      if (this.optionsNameArr.indexOf(tag.id) != -1) {
        this.optionsNameArr.splice(this.optionsNameArr.indexOf(tag.id), 1)
      }
      this.optionsNameArr.splice(i, 1)

      let a = []
      this.optionsNameArr.forEach((e) => {
        a.push(e.id)
      })
      this.form.industryIds = a.join()

      // if (tag.optionsId != 60) {
      //   this.checkList.forEach((item, i) => {
      //     this.handleCheckedCitiesChange(i)
      //   })
      // } else {
      //   this.valueArr = null
      // }
    },


    handleDialogCancel() {
      this.centerDialogVisible = false
      //   this.getid()
      // this.getAmountDetails(this.$route.query.id)
    },
  
    // 删除元素
    deleteItem(o) {
      if (o != 0) {
        this.form.options.splice(o, 1)
      }
    },

    addCharges1() {
      if (this.optionsNameArr) {
        this.value3 = []
        this.optionsNameArr.forEach((e, v) => {
          this.value3.push([e.pid, e.id])
        })
      }

      this.centerDialogVisible = true
      console.log(
        this.value3,
        '888888888888888888888======================================='
      )
    },
    addCharges() {
      this.form.options.push({})
    },
    //获取服务商详情
    getAmountDetails(id) {
      amount
        .industryid({
          id: id,
        })
        .then((res) => {
          this.optionsNameArr = res.data

          console.log(
            'res :>>33333333333333333333333333333333333333333333 ',
            this.value3
          )
        })

      amount
        .amountGet({
          id: id,
        })
        .then((res) => {
          console.log('res :>> ', res)
          this.form.name = res.data.name
          this.form.telAAreaIntercept = res.data.telAAreaIntercept
          this.form.telBAreaIntercept = res.data.telBAreaIntercept

          this.form.monthlyRent = res.data.monthlyRent
          this.form.concurren = res.data.concurren
          this.form.mode = res.data.mode
          this.form.description = res.data.description
          this.form.options = JSON.parse(res.data.options)
          this.form.id = res.data.id
          this.form.report = res.data.report
        })
    },
 getBaseTree(data, returnData) {

      if (!data) return
      if (Array.isArray(data)) {
        data.forEach((v, i) => {
          if (v.children) {
            returnData?.push({
              label: v.name,
              value: v.name,
              children: [],
            })
          } else {
            returnData?.push({
              label: v.name,
              value: v.name,
            })
          }

          if (v.children) {
            this.getBaseTree(v.children, returnData?.[i]?.children)
          }
        })
      }
      // return returnData;
      this.option = returnData

      // console.log(returnData, 'returnDatareturnDatareturnData')
    },

    getlabel(e) {
      e.forEach((v) => {
        v.children.forEach((x) => {
          this.label.push(x)
        })
      })

      this.label = JSON.parse(JSON.stringify(this.label))
      console.log(
        JSON.parse(JSON.stringify(this.label)),
        'lllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll'
      )
    },

    getid() {
      dictionary
        .industryid({
          id: this.$route.query.id,
        })
        .then((res) => {
          //  console.log(res.data, '99999999999999999999999999999999999')
        })
    },
    // 获取线路模块表
    getModeList() {
      amount.industryidlist().then((res) => {
        //     console.log(res.data, '99999999999999999999999999999999999========================================')

        this.label = res.data
        //   let arr = []
        // this.getBaseTree(res.data, arr)
        ///  this.modeList = res.data
        // this.getlabel(res.data)
      })
      dictionary
        .queryName({
          type: 'mode',
        })
        .then((res) => {
          this.modeList = res.data
        })
      dictionary
        .query({
          type: 'surcharge',
        })
        .then((res) => {
          this.surchargeList = res.data
          this.surchargeList.forEach((item, i) => {
            this.surchargeList[i][item.name] = 0
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.buttonli {
  display: flex;
 // position: fixed;

}
.button {
  margin-top: 20px;
  //margin-left: 10px;
}
.width-280 {
  z-index: 0;
}

:deep(.el-overlay) {
  z-index: 999999999991;
  background-color: rgba(0, 0, 0, 0);
}

.box {
  display: flex;
  .left {
    display: flex;
    width: 35%;
    flex-direction: column;
    justify-content: space-between;
  }
  .right {
    width: 60%;
    flex-direction: column;
  }
}

.width-280 {
  width: 380px;
}

.line-el-select {
  width: 80%;
}
.num-input {
  margin-left: 16px;
}
.icon-help-box {
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  .icon-help {
    font-size: 18px;
  }
  .icon-help-text {
    display: none;
  }
}
.icon-help-box:hover .icon-help-text {
  display: block;
  position: absolute;
  top: 45px;
  left: -13px;
  z-index: 20;
  width: 240px;
  padding: 16px;
  border-radius: 0.5rem;
  opacity: 0.75;
  background: #000000;
  box-shadow: 0px 3px 28px 0px rgba(0, 0, 0, 0.1);
}
// 三角形
.triangle {
  position: absolute;
  top: -12px;
  left: 15px;
  border-style: solid;
  border-width: 0px 8px 12px 8px;
  opacity: 1.52;
  // background: #000000;
  border-color: transparent transparent #000000 transparent;
  width: 0px;
  height: 0px;
}
.el-input-width {
  width: 220px;
}
.unit-box {
  margin-left: 9px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.rolling-box {
  height: 450px;
  padding-right: 16px;
  overflow: overlay;
}
.button-left {
  color: #333333;
  background: #f2f3f8;
}
.button-right {

  color: #ffffff;
  background: #637dff;
}
.delete-text {
  margin-left: 30px;
  cursor: pointer;
  color: #f56c6c;
}
.button-box {
  background: #fff;
  height: 60px;
}
.buy-name {
  font-size: 14px;
  font-weight: 700;
  color: #637dff;
}
.width-280 {
  width: 380px;
}

.m-l-10 {
  margin-left: 10px !important;
  color: #909399;
}
.m-t-10 {
  margin-top: 10px !important;
}
::v-deep(.div [class*='el-icon-'] + span) {
  margin: 0;
}
.comfirebtn {
  float: left;
  margin: 0 0 0 10px;
  width: 80px;
  height: 32px;
  background: #637dff;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  &.r {
    float: right;
    margin: 0 10px 0 0;
  }
}
.backbtn {
  float: left;
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  &.r {
    float: right;
    margin: 0 10px 0 0;
  }
}
.SetLine {
  padding-right: 1.125rem;
}
.lineCard-show-box {
  padding-top: 20px;
  height: calc(100vh - 250px);
  overflow-y: overlay;
}
.type-name {
  width: 80px;
}
.line-in-button {
  width: 100%;
}
.button-left {
  color: #333333;
  background: #f2f3f8;
}
.button-right {
  color: #ffffff;
  background: #637dff;
}
.font-refresh-16 {
  font-size: 14px;
  color: #637dff;
}
.df-item {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #09132d;
  letter-spacing: 0px;
  margin: 0 20px;
  cursor: pointer;
  &.active {
    color: #637dff;
  }
}
.flex {
  display: flex;
  &.center {
    align-items: center;
  }
  &.mt {
    margin-top: 10px;
  }
}
.text-gray {
  color: #86909c;
}
.text-black {
  color: #000;
}
.configtext {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #09132d;
}
.append-margin {
  margin-top: 15px;
}
::v-deep(.header-row th) {
  background: #eeeef3;
}
.button-text-edit {
  color: #637dff;
}
.button-text-del {
  color: #ff5757;
}
.noconfig-context {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .noconfig-img {
    width: 113px;
    margin-top: 180px;
  }
  .noconfig-text {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #86909c;
    margin-top: 28px;
  }
  .noconfig-btn {
    width: 108px;
    height: 32px;
    background: #637dff;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
  }
}
.dialogfooter {
  display: flex;
  flex-direction: row-reverse;
  // justify-content: space-around;
  //  align-items: center;
  .cancel {
    width: 94px;
    height: 36px;
    background: #eeeeee;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #131523;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .comfirm {
    margin-left: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 94px;
    height: 36px;
    background: #637dff;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    letter-spacing: 0px;
  }
}
.model_url {
  margin-left: 10px;
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>